import React from "react";
import Image from "../Images/authentic-design-back.png";

function AuthenticDesign() {
  return (
    <div className="about">
      <div className="about-container about-secondary mt-0">
        <div className="about-img-box">
          <img src={Image} alt=""/>
        </div>
        <div className="about-text-box">
          <h4>AUTHENTIC DESIGNS</h4>
          <h2>
            Who are the <br />
            creators of <br />
            CCGBTCONE?
          </h2>
          <p className="text-desc">
            The Japanese people behind the crypto currency girls series, which have turned some of the world's largest cryptocurrencies into anime characters, are also the creators of the CCGBTCONE collectible.
            <br /><br />
            A total of three cards from the series, were added to the Oasis Mining curated collection back in 2017.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://crypto-currency-girls.com"
            className="about-btn"
          >
            Check them out!
          </a>
        </div>
      </div>
    </div>
  );
}

export default AuthenticDesign;
