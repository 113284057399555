import React from "react";
import "./css/faq.css";

function Faq() {
  return (
    <div className="row justify-content-center faq-section" id="faq">
      <h1 className="faq-heading">FAQ</h1>
      <div className="col-9">
        <div class="accordion accordion-flush" id="accordionFlushExample">
          <div
            class="accordion-item mb-3"
            style={{ backgroundColor: "#29415e" }}
          >
            <h2 class="accordion-header" id="flush-headingOne">
              <button
                class="accordion-button collapsed"
                style={{ backgroundColor: "#29415e", color: "white" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                What is CCGBTCONE?
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="accordion-body">
                CCGBTCONE is a vintage collectible minted July 26th 2017 on the
                Bitcoin blockchain through the Counterparty protocol. It’s also
                part of the {" "}
                <a href="https://rarejapanesenfts.com/collection/oasis-mining/" rel="noreferrer" target="_blank">
                Oasis Mining
                </a> {" "}
                collection which consists of 66
                different cards from different artists. It was used by Elon Musk
                on many important occasions.
              </div>
            </div>
          </div>

          <div
            class="accordion-item mb-3 "
            style={{ backgroundColor: "#29415e" }}
          >
            <h2 class="accordion-header" id="flush-headingTwo">
              <button
                class="accordion-button collapsed"
                style={{ backgroundColor: "#29415e", color: "white" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                When was it used by Elon Musk (proof)?
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
              style={{ backgroundColor: "white" }}
            >
              <div class="accordion-body">
                
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/elonmusk/status/1054520588734058496?s=21"
                >
                   ELON MUSK Oct 23, 2018, Twitter
                </a> 
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/elonmusk/status/1256353943765921792?s=21"
                >
                  ELON MUSK May 2, 2020, Twitter
                </a> 
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://web.archive.org/web/20210220005710/https://twitter.com/elonmusk"
                >
                  ELON MUSK Feb 20, 2021, Twitter
                </a> 
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://web.archive.org/web/20210605144043/https://twitter.com/elonmusk "
                >
                  ELON MUSK Jun 05, 2021, Twitter
                </a> 
                
              </div>
            </div>
          </div>

          <div
            class="accordion-item mb-3"
            style={{ backgroundColor: "#29415e" }}
          >
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                style={{ backgroundColor: "#29415e", color: "white" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                On which blockchain is the CCGBTCONE collectible located?
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              class="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="accordion-body">
              On the Bitcoin blockchain, through the{" "}
                <a
                  href="https://counterparty.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Counterparty protocol
                </a> - and yes, NFT’s existed even prior to Ethereum in 2015 and before. With the help of Emblem Vault, assets from other blockchains can be vaulted and traded on ethereum, on marketplaces like Opensea, LooksRare and others. More on that below.
              </div>
            </div>
          </div>

          <div
            class="accordion-item mb-3"
            style={{ backgroundColor: "#29415e" }}
          >
            <h2 class="accordion-header" id="flush-headingThree">
              <button
                class="accordion-button collapsed"
                style={{ backgroundColor: "#29415e", color: "white" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour"
                aria-expanded="false"
                aria-controls="flush-collapseFour"
              >
                What is Counterparty and how to use it?
              </button>
            </h2>
            <div
              id="flush-collapseFour"
              class="accordion-collapse collapse"
              aria-labelledby="flush-collapseFour"
              data-bs-parent="#accordionFlushExample"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="accordion-body">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://en.wikipedia.org/wiki/Counterparty_(platform)"
                >
                  Counterparty Platform
                </a>
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://coincentral.com/counterparty-xcp-beginners-guide/"
                >
                  Counterparty XCP beginners guide
                </a>
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://counterparty.io/docs/faq/"
                >
                  Counterparty.io FAQ
                </a>{" "}
                <br />
                Please follow  {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/CounterpartyXCP"
                >
                  CounterpartyXCP 
                </a>{" "}
                and join their socials in order to learn more on twitter
              </div>
            </div>
          </div>

          <div
            class="accordion-item mb-3"
            style={{ backgroundColor: "#29415e" }}
          >
            <h2 class="accordion-header" id="flush-headingFive">
              <button
                class="accordion-button collapsed"
                style={{ backgroundColor: "#29415e", color: "white" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFive"
                aria-expanded="false"
                aria-controls="flush-collapseFive"
              >
                What is Emblem Vault and how to use it?
              </button>
            </h2>
            <div
              id="flush-collapseFive"
              class="accordion-collapse collapse"
              aria-labelledby="flush-collapseFour"
              data-bs-parent="#accordionFlushExample"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="accordion-body">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://desktopcommando.medium.com/what-is-emblemvault-14aaaff92a20"
                >
                  What is Emblem Vault
                </a>
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://desktopcommando.medium.com/how-to-use-emblemvault-14ba241ca42a"
                >
                  How to use Emblem Vault
                </a>
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://desktopcommando.medium.com/trading-xcp-nfts-on-ethereum-matic-networks-87a89101fd58"
                >
                  Trading XCP NFTs
                </a>{" "}
                <br />
                Please follow &nbsp;
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/EmblemVault"
                >
                 Emblem Vault on Twitter
                </a>{" "}
                and join their socials in order to learn more on twitter. 
              </div>
            </div>
          </div>

          <div
            class="accordion-item mb-3"
            style={{ backgroundColor: "#29415e" }}
          >
            <h2 class="accordion-header" id="flush-headingSix">
              <button
                class="accordion-button collapsed"
                style={{ backgroundColor: "#29415e", color: "white" }}
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseSix"
                aria-expanded="false"
                aria-controls="flush-collapseSix"
              >
                How to buy CCGBTCONE?
              </button>
            </h2>
            <div
              id="flush-collapseSix"
              class="accordion-collapse collapse"
              aria-labelledby="flush-collapseSix"
              data-bs-parent="#accordionFlushExample"
              style={{ backgroundColor: "#ffffff" }}
            >
              <div class="accordion-body">
                Before you do that, please make sure to familiarize yourself with how emblem vault and counterparty works, by joining our discord. 
                <br /><br />
                There are two ways of buying CCGBTCONE
                <br /><br />
                1) On marketplaces like Opensea through Emblem Vault :
                <br />
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://opensea.io/collection/emblem-vault?search[sortAscending]=true&search[sortBy]=PRICE&search[stringTraits][0][name]=Oasis%20Mining&search[stringTraits][0][values][0]=CCGBTCONE"
                >
                  Opensea Emblem Vault
                </a>
                <br />
                Make sure to always properly check the vault before buying: {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://desktopcommando.medium.com/a-buyers-guide-to-using-emblem-vault-on-opensea-io-bb0d0a206c1a"
                >
                  Buyers Guide to Emblem Vault
                </a>
                <br />
                <br />
                2) Directly on Counterparty XCP through the decentralized exchange. For this method please join our discord for more information.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
