import React from "react";
import Image from "../Images/about-us-back.png";
import "./css/about.css";

function AboutUs() {
  return (
    <div className="about" id="about">
      <div className="about-container ">
        <div className="about-img-box">
          <img src={Image} alt="" />
        </div>
        <div className="about-text-box">
          <h4>ABOUT US</h4>
          <h2>
            Welcome to <br />
            CCGBTCONE <br />
            NFT Club
          </h2>
          <p className="text-desc">
            CCGBTCONE is a vintage collectible minted July 26th 2017 on the Bitcoin blockchain through the Counterparty protocol. It's part of the Oasis Mining collection which consists of 66 different cards from different artists.
            <br />
            <br />
            It's one of the first Waifu NFT's minted on the blockchain and was used in multiple occasions by Elon Musk in his Twitter posts and even as his profile picture.
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.com/invite/ccgbtcone"
            className="about-btn"
          >
            Join our discord
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
