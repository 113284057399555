import React from "react";
import "./css/bitcoin.css";
import BitcoinImg from "../Images/bitcoin-anime.png";
import elon from "../Images/elon.png";
import post1 from "../Images/post1.png";
import post2 from "../Images/post-2.png";

function Bitcoin() {
  return (
    <div className="bitcoin-section-container">
      <section className="bitcoin-section">
        <div className="bitcoin-row">

          <div className="bitcoin-left">
            <img src={elon} className="elon-img"  alt="" />
            <img src={post1} alt="" className="post1" />
          </div>

          <div className="bitcoin-center">
            <img src={BitcoinImg} alt="" />
          </div>

          <div className="bitcoin-right">
            <img src={post2} alt=""  />
          </div>

        </div>
      </section>
    </div>
  );
}

export default Bitcoin;
