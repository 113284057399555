import React from "react";
import "./css/footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {} from "@fortawesome/free-solid-svg-icons";
import {
  faTwitter,
  faDiscord,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
// import FooterLogo from "../Icons/FooterLogo";
import FooterLogo from "../Images/big_logo.0158115.svg";

function Footer() {
  return (
    <footer className="row footer">
      <div className="footer-container">
        <div className="footer-left">
          {/* <FooterLogo></FooterLogo> */}
          <img src={FooterLogo} alt="" />
          <p className="text-white">
            © 2022 CCGBTCONE NFT CLUB. All rights reserved
          </p>
        </div>

        <div className="footer-right">
          <div className="">
            <h3>Useful Links</h3>
            <p>
              <a href="#home">Home</a>
            </p>
            <p>
              <a href="#about">About</a>
            </p>
            <p>
              <a href="#faq">Faq</a>
            </p>
          </div>
          <div className="">
            <h3>Socials</h3>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://twitter.com/CCGBTCONE"
              >
                <FontAwesomeIcon
                  className="me-md-2"
                  icon={faTwitter}
                  color="white"
                />{" "}
                Twitter
              </a>
            </p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://discord.com/invite/ccgbtcone"
              >
                <FontAwesomeIcon
                  className="me-md-2"
                  icon={faDiscord}
                  color="white"
                />{" "}
                Discord
              </a>
            </p>
            <p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://medium.com/@coin_and_peace/history-of-vintage-nfts-in-japan-the-almost-forgotten-part-of-nft-history-5f15ce50a828"
              >
                <FontAwesomeIcon
                  className=" me-md-2"
                  icon={faMedium}
                  color="white"
                />{" "}
                Medium
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
