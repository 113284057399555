import React, { useState } from "react";
import "./header.css";
import Logo from "../Images/logo.svg";
import { Link } from "react-router-dom";
// import TwitterIcon from "../Icons/Twiiter";
// import DiscordIcon from "../Icons/Discord";
// import MediumIcon from "../Icons/Medium";
import DiscordIcon from "../Images/discord.svg"
import MediumIcon from "../Images/medium.svg"
import TwitterIcon from "../Images/twitter.svg"

function Header() {
  const [isActive, setIsActive] = useState(false);


  return (
    <nav class="navbar navbar-dark header">
      <div className="header-container">
        <div className="header-left">
          <Link class="navbar-brand" to="/">
            <img src={Logo} className="logo" alt="" />
          </Link>
          <div className="header-divider d-none d-md-block "></div>

          <div className="header-social-links">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/CCGBTCONE"
            >
            {/* <TwitterIcon></TwitterIcon> */}
              <img src={TwitterIcon} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.com/invite/ccgbtcone"
            >
              {/* <DiscordIcon></DiscordIcon> */}
              <img src={DiscordIcon} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://medium.com/@coin_and_peace/history-of-vintage-nfts-in-japan-the-almost-forgotten-part-of-nft-history-5f15ce50a828"
            >
              <img src={MediumIcon} alt="" />
              {/* <MediumIcon></MediumIcon> */}
            </a>
          </div>

        </div>

        <div className={isActive ? "header-right active" : 'header-right'} >
          <a class="" href="#home">Home</a>
          <a class="" href="#about">About</a>
          <a class="" href="#faq">FAQ</a>
          <a href="https://opensea.io/collection/emblem-vault?search%5BsortAscending%5D=true&search%5BsortBy%5D=PRICE&search%5BstringTraits%5D%5B0%5D%5Bname%5D=Oasis%20Mining&search%5BstringTraits%5D%5B0%5D%5Bvalues%5D%5B0%5D=CCGBTCONE" rel="noreferrer" target="_blank" className="header-btn">Buy on Opensea</a>
          <div className="header-social-links">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/CCGBTCONE"
            >
              <img src={TwitterIcon} alt="" />
            {/* <TwitterIcon></TwitterIcon> */}
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.com/invite/ccgbtcone"
            >
              {/* <DiscordIcon></DiscordIcon> */}
              <img src={DiscordIcon} alt="" />
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://medium.com/@coin_and_peace/history-of-vintage-nfts-in-japan-the-almost-forgotten-part-of-nft-history-5f15ce50a828"
            >
              <img src={MediumIcon} alt="" />
              {/* <MediumIcon></MediumIcon> */}
            </a>
          </div>

        </div>

        <button className="menu-toggler" onClick={() => {setIsActive(!isActive)}}>
          <svg className="" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" /></svg>
        </button>
      </div>
    </nav>
  );
}

export default Header;
