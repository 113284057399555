import React from "react";
import post1 from "../Images/slider/slider-1.jpg";
import post2 from "../Images/slider/slider-2.jpg";
import post3 from "../Images/slider/slider-3.jpg";
import post4 from "../Images/slider/slider-4.jpg";
import post5 from "../Images/slider/slider-5.jpg";
import "./css/crousal.css";

function Crousal() {
  return (
    <div className="mt-5 row">
      {/* Crousel  */}
      <div className="col-12 col-md-12 m-auto">
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="carousel-inner text-center">
            <div class="carousel-item active">
              <a href="https://twitter.com/elonmusk/status/1054520588734058496?s=21" target="_blank">
                <img src={post1} class="d-block cr-image" alt="..." />
              </a>
            </div>
            <div class="carousel-item">
              <a href="https://twitter.com/elonmusk/status/1256353943765921792?s=21" target="_blank">
                <img src={post2} class="d-block cr-image" alt="..." />
              </a>
            </div>
            <div class="carousel-item">
              <a href="https://twitter.com/cz_binance/status/1054584958830800896" target="_blank">
                <img src={post3} class="d-block cr-image" alt="..." />
              </a>
            </div>
            <div class="carousel-item">
              <a href="https://web.archive.org/web/20210220005710/https://twitter.com/elonmusk" target="_blank">
                <img src={post4} style={{height : "300px"}}  class="d-block cr-image" alt="..." />
              </a>
            </div>
            <div class="carousel-item">
              <a href="https://twitter.com/elonmusk/status/1054501056229588992" target="_blank">
                <img src={post5}  height="150" style={{height : "300px"}} class="d-block cr-image" alt="..." />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Buttons  */}
      <div className="" >
        <div
          id="carouselExampleIndicators"
          class="carousel slide"
          data-bs-ride="carousel"
        >
          <div class="m-auto" style={{width:'max-content'}} >
            <button
              className="crousel-buttons active ms-0"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              className="crousel-buttons"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              className="crousel-buttons"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              className="crousel-buttons"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              className="crousel-buttons"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Crousal;
