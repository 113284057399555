import React from "react";
import Image from "../Images/epic-story-back.png";

function EpicStory() {
  return (
    <div className="about">
      <div className="about-container about-reverse">
        <div className="about-img-box">
          <img src={Image} alt=""/>
        </div>
        <div className="about-text-box">
          <h4>EPIC STORY</h4>
          <h2>
            Elon Musk’s <br />
            involvement
          </h2>
          <p className="text-desc">
            The first time it was used by Elon was in October 2018 in a tweet exchange with @Grimezsz. His account was even locked by Twitter, thinking hackers got control of it.
            <br /><br />
            Back then his tweet was retweeted by many known people in the cryptocurrency space as @cz_binance @DoveyWan and many others. It was used in May 2020 in an exchange with @bitcoin twitter account and it played a major role on kickstarting the bitcoin run we saw back then.
            <br /><br />
            On February 20th 2021, Elon used CCGBTCONE as his profile picture on twitter along with laser eyes, which seems to have contributed a lot to the laser eyes trend that we saw last year.
          </p>
        </div>
      </div>
    </div>
  );
}

export default EpicStory;
