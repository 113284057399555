import React from "react";
import "./css/video.css";
import VideoThumbnail from "../Images/hero-video-thumbnail.png"

function Video() {
  return (
    <section className="video-section videourl">
      <a href="#!">
        {/* <img src={VideoThumbnail} alt="" className="video-thumbnail"/> */}
        <iframe  className="video-thumbnail" src="https://www.youtube.com/embed/bEXAhPZad8k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </a>
    </section>
  );
}

export default Video;
